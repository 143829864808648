import React, { useState } from "react";
import { StyledSelect } from "../../../../Form/FormControls";
import DataSourceAction from "./DataSourceAction";
import ServicerTransferFeedAction from "./ServicerTransferFeedAction";
import EtlPipelineAction from "./EtlPipelineAction";
import { v4 as uuid } from "uuid";
import Button from "../../../../Button";

const ActionSelector = ({
  dispatch,
  segment,
  dependencies,
  stateSources,
  stateTransformations,
  isInserting,
}) => {
  const parentId = isInserting?.segment?.id;
  const parentName = isInserting?.segment?.name;
  const parentEtlProviderInstanceId =
    isInserting?.segment?.etlProviderInstanceId;
  const parentType = isInserting?.segment?.type;

  const initSegment =
    isInserting?.insertType === "child"
      ? {
          name: null,
          dataSourceDependencies:
            parentType === "DATA_SOURCE" ? [parentId] : [],
          pipelineDependencies:
            parentType === "ETL_PIPELINE"
              ? [
                  {
                    name: parentName,
                    etlProviderInstanceId: parentEtlProviderInstanceId,
                  },
                ]
              : [],
          transformationDependencies:
            parentType === "TRANSFORMATION" ? [parentId] : [],
          id: null,
          uid: uuid(),
          isTermination: segment?.isTermination,
          type: null,
          etlProviderInstanceId: null,
          dataQualityThreshold: null,
          currentThreshold: 0,
        }
      : isInserting?.insertType === "link"
      ? {
          name: null,
          dataSourceDependencies: [],
          pipelineDependencies: [],
          transformationDependencies: [],
          id: null,
          uid: uuid(),
          isTermination: segment?.isTermination,
          type: null,
          etlProviderInstanceId: null,
          dataQualityThreshold: null,
          currentThreshold: 0,
        }
      : null;

  const [insertSegment, setInsertSegment] = useState(initSegment);

  console.log({ isInserting, insertSegment }, "state inserts");

  const actionTypeOptions = [
    { value: "DATA_SOURCE", label: "Data Source" },
    { value: "ETL_PIPELINE", label: "ETL Pipeline" },
    { value: "TRANSFORMATION", label: "Servicer Transfer Feed" },
  ];

  const [selectedActionType, setSelectedActionType] = useState(
    segment?.type && !isInserting
      ? actionTypeOptions.find((o) => o.value === segment.type)
      : null
  );

  const handleActionTypeChange = (selectedOption) => {
    if (isInserting) {
      setSelectedActionType(selectedOption);
      setInsertSegment({
        ...initSegment,
        type: selectedOption?.value,
      });
    } else {
      setSelectedActionType(selectedOption);
      dispatch({
        type: "SELECT_DEPENDENCY_TYPE",
        payload: {
          pipeline: segment,
          type: selectedOption?.value,
        },
      });
    }
  };
  // Once parent is found, search for segments that depend on this parent
  let referencingSegments = [];

  if (isInserting && isInserting?.insertType === "child") {
    // Helper function to find segments that depend on a given id
    const findDependentSegment = (targetId, depType) => {
      return dependencies.find((dep) => dep[depType].includes(targetId));
    };

    const findDependentSegmentETL = (targetEtlId, targetEtlName, depType) => {
      return dependencies.find((dep) =>
        dep[depType].forEach((d) => {
          return (
            d.etlProviderInstanceId === targetEtlId && d.name === targetEtlName
          );
        })
      );
    };

    // Find the parent and child by traversing dependencies
    let parent = null;
    let child = null;

    // Identify the parent and child segments based on existing dependencies using id
    dependencies.forEach((dep) => {
      if (
        dep.id === segment.id &&
        isInserting?.insertType === "child" &&
        dep.type !== "ETL_PIPELINE"
      ) {
        parent = dep; // Current segment is the parent
      } else if (
        dep.etlProviderInstanceId === segment.etlProviderInstanceId &&
        isInserting?.insertType === "child" &&
        dep.type === "ETL_PIPELINE"
      ) {
        parent = dep; // Current segment is the parent
      }
      console.log({ parent, type: dep.type });
      // Check if current dependency is a parent of the inserting segment using id and type
      if (
        findDependentSegment(segment.id, "dataSourceDependencies") ||
        findDependentSegmentETL(
          segment.etlProviderInstanceId,
          segment.name,
          "pipelineDependencies"
        ) ||
        findDependentSegment(segment.id, "transformationDependencies")
      ) {
        child = dep; // Current segment is a child
      }
    });
    console.error(parent, "parent id?");
    console.error(child, "child id?");
    if (!parent || !child) {
      console.error("Could not identify parent or child");
      // return;
    }

    // Find all segments that reference the parent
    dependencies.forEach((dep) => {
      const isReferencingParent =
        dep.dataSourceDependencies.includes(parent.id) ||
        dep.pipelineDependencies.includes(parent.id) ||
        dep.transformationDependencies.includes(parent.id);

      if (isReferencingParent) {
        referencingSegments.push(dep);
      }
    });
    console.log({ referencingSegments }, "before");
    // Update referencing segments to point to the new inserted segment
    referencingSegments = referencingSegments.map((dep) => {
      const updatedDep = { ...dep };

      if (isInserting?.insertType === "child") {
        // Go through all dependency types and update the references
        [
          "dataSourceDependencies",
          "pipelineDependencies",
          "transformationDependencies",
        ].forEach((depType) => {
          updatedDep[depType] = updatedDep[depType].map((referenceId) =>
            referenceId === parent.id ? insertSegment.id : referenceId
          );
        });
      }

      // if (isInserting?.insertType === "link") {
      //   // Go through all dependency types and update the references
      //   [
      //     "dataSourceDependencies",
      //     "pipelineDependencies",
      //     "transformationDependencies",
      //   ].forEach((depType) => {
      //     updatedDep[depType] = updatedDep[depType].flatMap((referenceId) =>
      //       referenceId === parent.id
      //         ? [referenceId, insertSegment.id]
      //         : [referenceId]
      //     );
      //   });
      // }

      return updatedDep;
    });
    console.log({ referencingSegments }, "after");
  }

  if (isInserting && isInserting.insertType === "link") {
    const targetId = isInserting.segment.id;

    // Find all segments that depend on the target segment
    const inreferencingSegments = dependencies.filter((dep) =>
      [
        "dataSourceDependencies",
        "pipelineDependencies",
        "transformationDependencies",
      ].some((depType) => dep[depType]?.includes(targetId))
    );

    // Update referencing segments to include the new segment
    referencingSegments = inreferencingSegments.map((dep) => {
      const updatedDep = { ...dep };

      [
        "dataSourceDependencies",
        "pipelineDependencies",
        "transformationDependencies",
      ].forEach((depType) => {
        if (updatedDep[depType]?.includes(targetId)) {
          // Add insertSegment.id to the dependencies if not already present
          updatedDep[depType] = Array.from(
            new Set([...updatedDep[depType], insertSegment.id])
          );
        }
      });

      return updatedDep;
    });
  }

  return (
    <div>
      <StyledSelect
        name="actionType"
        label="Action Type"
        options={
          dependencies?.length === 1
            ? actionTypeOptions.filter((o) => o.value !== "ETL_PIPELINE")
            : actionTypeOptions
        }
        value={selectedActionType}
        onChange={handleActionTypeChange}
        placeholder="Select Action Type"
        menuPortalTarget={document.body}
      />

      <div>
        {selectedActionType?.value === "DATA_SOURCE" ? (
          <DataSourceAction
            dispatch={dispatch}
            segment={isInserting ? insertSegment : segment}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            isInserting={isInserting}
            setInsertSegment={setInsertSegment}
          />
        ) : selectedActionType?.value === "ETL_PIPELINE" ? (
          <EtlPipelineAction
            dispatch={dispatch}
            segment={isInserting ? insertSegment : segment}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            isInserting={isInserting}
            setInsertSegment={setInsertSegment}
          />
        ) : selectedActionType?.value === "TRANSFORMATION" ? (
          <ServicerTransferFeedAction
            dispatch={dispatch}
            segment={isInserting ? insertSegment : segment}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            isInserting={isInserting}
            setInsertSegment={setInsertSegment}
          />
        ) : null}
      </div>

      {isInserting && (
        <Button
          onClick={() =>
            dispatch({
              type: "INSERT_SEGMENT",
              payload: {
                newSegment: insertSegment,
                segmentsToUpdate: referencingSegments,
              },
            })
          }
        >
          Insert
        </Button>
      )}
    </div>
  );
};

export default ActionSelector;
