import React from "react";
import {
  Pipeline,
  SegmentVerticalLine,
  VerticalLine,
} from "./ViewWorkflowStyles";
import RenderSegment from "./RenderSegment";
import styled from "styled-components/macro";
import { MdCancel, MdCached, MdCheckCircle } from "react-icons/md";

const Indicators = styled.div`
  font-size: 1.8rem;
  align-items: center;
  display: flex;
`;

function showStateIcon(resultState) {
  switch (resultState) {
    case "NONE":
    case "CONNECTION_FAILURE":
    case "CANCELLED":
    case "PIPELINE_FAILED":
    case "GENERIC_FAILURE":
      return (
        <Indicators style={{ color: "#f87e7e" }} title="Failure">
          <MdCancel />
        </Indicators>
      );
    case "PIPELINE_QUEUED":
    case "PIPELINE_QUEUED_AND_STARTED":
    case "PIPELINE_STARTED":
      return (
        <Indicators style={{ color: "#009fd4" }} title="In Progress">
          <MdCached />
        </Indicators>
      );
    case "PIPELINE_SUCCEEDED":
      return (
        <Indicators style={{ color: "green" }} title="Success">
          <MdCheckCircle />
        </Indicators>
      );
    default:
      return null;
  }
}

const RenderDependentPipelines = ({
  dependencies,
  pipelines,
  dispatch,
  stateSources,
  type,
  stateTransformations,
}) => {
  const numberOfPipelines = pipelines.length;
  return (
    <>
      {pipelines.map((pipeline, i) => {
        let segment;
        if (type === "ETL_PIPELINE") {
          segment = dependencies.find(
            (d) =>
              d.name === pipeline?.name &&
              d.type === type &&
              d?.etlProviderInstanceId === pipeline?.etlProviderInstanceId
          );
        } else {
          segment = dependencies.find(
            (d) => d.id === pipeline && d.type === type
          );
        }

        const isLast = numberOfPipelines === i + 1;
        return (
          <Pipeline key={`${segment?.uid}-renderseg`}>
            {/* TODO REMOVE DEBUG */}
            <RenderSegment
              dependencies={dependencies}
              segment={segment}
              dispatch={dispatch}
              isLast={isLast}
              stateSources={stateSources}
              stateTransformations={stateTransformations}
            />

            <SegmentVerticalLine>
              <VerticalLine />
            </SegmentVerticalLine>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "35px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  borderRadius: "50%",
                  width: "35px",
                  height: "35px",
                  background: "#f7f7f7",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {showStateIcon(segment?.resultState)}
              </div>
            </div>
            <SegmentVerticalLine>
              <VerticalLine />
            </SegmentVerticalLine>
          </Pipeline>
        );
      })}
    </>
  );
};

export default RenderDependentPipelines;
