import React from "react";
import { StyledSelect } from "../../../../Form/FormControls";
import {
  DataSourceWrapper,
  DataSourceHeading,
  FieldLabel,
} from "../CreateFormStyles";

const ServicerTransferFeedAction = ({
  dispatch,
  segment,
  stateTransformations,
  setInsertSegment,
  isInserting,
  insertSegment,
}) => {
  // Assuming you have a list of servicer transfer feeds

  const selectedFeed = stateTransformations.find((f) => f.value === segment.id);

  return (
    <div style={{ padding: ".4rem" }}>
      <DataSourceWrapper>
        <DataSourceHeading>
          <FieldLabel>Servicer Transfer Feed</FieldLabel>
        </DataSourceHeading>
        <StyledSelect
          name={`stf-${segment?.id}`}
          label="Servicer Transfer Feed"
          options={stateTransformations}
          value={selectedFeed}
          onChange={(e) =>
            dispatch({
              type: "SELECT_TRANSFER",
              payload: {
                pipeline: segment,
                transfer: e,
              },
            })
          }
          placeholder="Select Servicer Transfer Feed"
        />
      </DataSourceWrapper>
    </div>
  );
};

export default ServicerTransferFeedAction;
