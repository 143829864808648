import React, { useState, useCallback, useEffect } from "react";
import {
  DataSourceWrapper,
  DataSourceHeading,
  FieldLabel,
} from "../CreateFormStyles";
import {
  availableEtlProviders,
  browseForEtlProvidersPipelines,
} from "../../../../../api/etlProviderQueries";
import useETLProviderPipelineBrowseNotification from "../../../../../Hooks/useETLProviderPipelineBrowseNotification";
import { useApi } from "../../../../../api/useApi";
import { sortByAlpha } from "../../../../../common/helpers/util";
import SplashLoader from "../../../../Loaders/SplashLoader";
import ErrorMessages from "../../../../../components/Notifications/ErrorMessages";
import {
  FormControl,
  StyledSelect,
  Label,
} from "../../../../Form/FormControls";

const EtlPipelineAction = ({
  dispatch,
  dependencies,
  segment,
  setInsertSegment,
  isInserting,
  insertSegment,
}) => {
  const [noProvidersMessage, setNoProvidersMessage] = useState("");
  const [etlProviderInstanceId, setEtlProviderInstanceId] = useState(
    segment?.etlProviderInstanceId ?? null
  );

  // Providers State
  const [providers, setProviders] = useState([]);

  // Instances Names State
  const [instanceNames, setInstanceNames] = useState([]);
  const [selectedInstanceName, setSelectedInstanceName] = useState(
    segment?.name ?? ""
  );

  // Load Available Providers
  const [{ errors, data }] = useApi(availableEtlProviders);

  // Browse API Call
  const [{ errors: browseDataRequestErrors }, browse] = useApi();

  // Set locally browsed failure
  const [browseFailures, setBrowseFailures] = useState();

  // Handle Browse Request
  const browseRequest = useCallback(
    (browseObject) => {
      browse({
        query: browseForEtlProvidersPipelines,
        variables: {
          browseInfo: { etlProviderInstanceId: browseObject },
        },
      });
    },
    [browse]
  );

  // Provider Browse Hook
  const { etlProviderPipelineBrowseCompleted, setEtlProviderBrowseCompleted } =
    useETLProviderPipelineBrowseNotification();

  // Handle Browse Results
  useEffect(() => {
    if (etlProviderPipelineBrowseCompleted) {
      const response = etlProviderPipelineBrowseCompleted.payload;
      if (response?.ErrorMessage) {
        setBrowseFailures([{ message: response?.ErrorMessage }]);
        setEtlProviderBrowseCompleted(null);
      } else {
        const items = response.BrowseItems.map((item) => {
          return {
            label: item.Name,
            value: item.Name,
          };
        });

        const sortedItems = items?.length ? sortByAlpha(items, "label") : [];

        setInstanceNames(sortedItems);
        setEtlProviderBrowseCompleted(null);
      }
    }
  }, [
    etlProviderPipelineBrowseCompleted,
    setInstanceNames,
    setEtlProviderBrowseCompleted,
    setBrowseFailures,
  ]);

  // Provider Handler
  useEffect(() => {
    if (data && data?.availableEtlProviders?.edges?.length) {
      const providers =
        data?.availableEtlProviders?.edges?.[0]?.node?.instances
          .filter((instance) => instance.enabled)
          .map((inst) => {
            return {
              label: inst.name,
              value: inst.id,
            };
          }) ?? [];

      setProviders(providers);
    } else if (data && !data?.availableEtlProviders?.edges?.length) {
      setNoProvidersMessage(
        "You have not configured an ETL Provider within the admin ETL settings."
      );
    }
  }, [data, setProviders]);

  useEffect(() => {
    if (etlProviderInstanceId) {
      browseRequest(etlProviderInstanceId);
    }
  }, [etlProviderInstanceId, browseRequest]);

  if (noProvidersMessage) {
    return <ErrorMessages errors={[{ message: noProvidersMessage }]} />;
  }

  if (!providers) {
    return <SplashLoader text={"Preparing Providers"} />;
  }

  if (browseDataRequestErrors?.length) {
    return <ErrorMessages errors={browseDataRequestErrors} />;
  }

  if (browseFailures?.length) {
    return <ErrorMessages errors={browseFailures} />;
  }

  if (errors?.length) {
    return <ErrorMessages errors={errors} />;
  }

  return (
    <div style={{ padding: ".4rem" }}>
      <div
        style={{
          flex: 1,
          marginLeft: "auto",
          marginRight: "1rem",
        }}
      >
        <FormControl>
          <Label>ETL Provider</Label>
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            name={`etlProviderInstanceId`}
            id={`etlProviderInstanceId`}
            inputId={`etlProviderInstanceIdSelect-input`}
            instanceId={`etlProviderInstanceIdSelect-instance`}
            label="Instance"
            options={providers}
            placeholder={`Select Instance`}
            value={
              providers?.find((p) => p.value === etlProviderInstanceId) ?? null
            }
            menuPortalTarget={document.body}
            menuPlacement="auto"
            onChange={(e) => {
              browseRequest(e?.value);
              setEtlProviderInstanceId(e?.value);

              isInserting
                ? setInsertSegment((prev) => {
                    return { ...prev, etlProviderInstanceId: e?.value };
                  })
                : dispatch({
                    type: "SET_PROVIDER",
                    payload: {
                      pipeline: segment,
                      etlProviderInstanceId: e?.value,
                    },
                  });
            }}
          />
        </FormControl>
        {etlProviderInstanceId && (
          <FormControl>
            <Label>ETL Provider Instance</Label>
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              name={`etlProviderInstanceId`}
              id={`etlProviderInstanceId`}
              inputId={`etlProviderInstanceIdSelect-input`}
              instanceId={`etlProviderInstanceIdSelect-instance`}
              label="Instance"
              options={instanceNames}
              placeholder={`Select Instance`}
              value={
                instanceNames?.find(
                  (isn) => isn?.value === selectedInstanceName
                ) ?? null
              }
              menuPortalTarget={document.body}
              menuPlacement="auto"
              onChange={(e) => {
                setSelectedInstanceName(e?.value);

                isInserting
                  ? setInsertSegment((prev) => {
                      return { ...prev, name: e?.value };
                    })
                  : dispatch({
                      type: "SELECT_ETL_INSTANCE",
                      payload: {
                        pipeline: segment,
                        name: e?.value,
                      },
                    });
              }}
            />
          </FormControl>
        )}
      </div>
    </div>
  );
};

export default EtlPipelineAction;
