import update from "immutability-helper";
import { v4 as uuid } from "uuid";
import { format } from "date-fns";

const CreateReducer = (state, action) => {
  switch (action.type) {
    //First State called when a query is passed to the component.
    default:
      return {
        ...state,
      };

    case "ADD_SEGMENT_PARENT": {
      //Add a parent to bottom segment
      console.log({ action }, "Add Segment Parent");
      //Create new ID
      const newID = uuid();

      //New Segment
      let newSegment = {
        name: null,
        dataSourceDependencies: [],
        pipelineDependencies: [],
        transformationDependencies: [],
        uid: newID,
      };

      //Clone Array
      const clone = [...state?.dependencies];

      // Get Current Segment
      const currentSegment = action?.payload;

      // Need to know parent, and child to update deps

      // Look at the PARENTS of Selected, Add New Segment as Child

      const updatedArray = clone.map((p, i) => {
        const findCurrentIndex = currentSegment.pipelineDependencies.indexOf(
          p.uid
        );

        if (p.uid === currentSegment.uid) {
          return { ...p, pipelineDependencies: [newID] };
        } else if (findCurrentIndex !== -1) {
          newSegment.pipelineDependencies.push(p.uid);
          return p;
        } else {
          return p;
        }
      });

      updatedArray.push(newSegment);

      return {
        ...state,
        dependencies: updatedArray,
      };
    }

    case "PREP_INSERT_SEGMENT": {
      return {
        ...state,
        isInsertingDep: action?.payload,
      };
    }

    case "TOGGLE_INSERT": {
      return {
        ...state,
        isInsertingDep: false,
      };
    }

    case "ADD_SEGMENT_CHILD": {
      //Add a child to bottom segment
      console.log({ action }, "Add Segment Child");
      //Create new ID
      const newID = uuid();

      //Clone Array
      const clone = [...state?.dependencies];

      // Get Current Segment
      const currentSegment = action?.payload;

      //New Segment
      let newSegment = {
        name: null,
        dataSourceDependencies: [],
        pipelineDependencies: [],
        transformationDependencies: [],
        uid: newID,
        isTermination: currentSegment?.isTermination,
        type: "DATA_SOURCE",
        etlProviderInstanceId: null,
        dataQualityThreshold: null,
      };

      if (action?.payload?.type === "DATA_SOURCE") {
        newSegment.dataSourceDependencies.push(action?.payload?.id);
      } else if (action?.payload?.type === "ETL_PIPELINE") {
        newSegment.pipelineDependencies.push({
          name: action?.payload?.name,
          etlProviderInstanceId: action?.payload?.etlProviderInstanceId,
        });
      } else if (action?.payload?.type === "TRANSFORMATION") {
        newSegment.transformationDependencies.push(action?.payload?.id);
      }

      // Need to know parent, and child to update deps

      const updatedArray = clone.map((p, i) => {
        //is the current segment found within the looped dep pipeline
        const findCurrentIndex = currentSegment.uid;

        console.log({ findCurrentIndex });

        // if the selected segment set new dep to have the current seg uid as a dep
        if (p.uid === currentSegment.uid) {
          // newSegment.pipelineDependencies = [p.uid];

          return { ...p, isTermination: false };
        } else {
          return p;
        }
      });
      console.log({ newSegment });
      //push new segment into array since order beyond [0] doesn't matter
      updatedArray.push(newSegment);

      return {
        ...state,
        dependencies: updatedArray,
      };
    }

    case "ADD_LINK_SEGMENT": {
      //Add a Link to side segment
      console.log({ action }, "Add Link Segment");
      //Create new ID
      const newID = uuid();

      //Clone Array
      const clone = [...state?.dependencies];

      // Get Current Segment
      const currentSegment = action?.payload;

      //New Segment
      let newSegment = {
        name: null,
        dataSourceDependencies: [],
        pipelineDependencies: [],
        transformationDependencies: [],
        uid: newID,
        isTermination: false,
      };

      // Need to know parent, and child to update deps

      const updatedArray = clone.map((p, i) => {
        //is the current segment found within the looped dep pipeline
        const findCurrentIndex = p.pipelineDependencies.indexOf(
          currentSegment.uid
        );

        if (findCurrentIndex !== -1) {
          // if looped segment has the current segment as a dep, change the current
          // dep to be the newly created uui for new segment add new id to array
          let newPipelines = [...p?.pipelineDependencies, newID];

          return { ...p, pipelineDependencies: newPipelines };
        } else {
          return p;
        }
      });

      //push new segment into array since order beyond [0] doesn't matter
      updatedArray.push(newSegment);

      return {
        ...state,
        dependencies: updatedArray,
      };
    }

    case "REMOVE_SEGMENT": {
      console.log("REMOVING");

      // Clone the array immutably
      let clone = state?.dependencies.map((dep) => ({ ...dep }));

      // Get the current segment
      const currentSegment = action?.payload;

      const {
        id,
        type,
        etlProviderInstanceId,
        name,
        dataSourceDependencies,
        transformationDependencies,
        pipelineDependencies,
      } = currentSegment;

      // To store child dependencies that will reference the current segment
      let children = [];

      // Identify the parent and child segments based on existing dependencies using id
      const updatedArr = clone
        .map((dep) => {
          // Clone the dependency object immutably
          let updatedDep = { ...dep };

          // Handle DATA_SOURCE case
          if (type === "DATA_SOURCE") {
            let segmentRemoved = false;
            updatedDep.dataSourceDependencies =
              dep.dataSourceDependencies.filter((d) => {
                if (d !== id) {
                  return true; // Keep other dependencies
                }
                segmentRemoved = true;
                return false; // Remove the current segment's dependency
              });

            if (segmentRemoved) {
              updatedDep.dataSourceDependencies = [
                ...updatedDep.dataSourceDependencies,
                ...dataSourceDependencies,
              ];
              children.push(updatedDep); // Push to children if found and removed
            }
          }

          // Handle TRANSFORMATION case
          if (type === "TRANSFORMATION") {
            let segmentRemoved = false;
            updatedDep.transformationDependencies =
              dep.transformationDependencies.filter((d) => {
                if (d !== id) {
                  return true; // Keep other dependencies
                }
                segmentRemoved = true;
                return false; // Remove the current segment's dependency
              });

            if (segmentRemoved) {
              updatedDep.transformationDependencies = [
                ...updatedDep.transformationDependencies,
                ...transformationDependencies,
              ];
              children.push(updatedDep); // Push to children if found and removed
            }
          }

          // Handle ETL_PIPELINE case (specialized case)
          if (type === "ETL_PIPELINE") {
            let segmentRemoved = false;
            updatedDep.pipelineDependencies = dep.pipelineDependencies.filter(
              (d) => {
                if (
                  d.etlProviderInstanceId === etlProviderInstanceId &&
                  d.name === name
                ) {
                  segmentRemoved = true;
                  return false; // Remove the current segment's dependency
                }
                return true; // Keep other dependencies
              }
            );

            if (segmentRemoved) {
              updatedDep.pipelineDependencies = [
                ...updatedDep.pipelineDependencies,
                ...pipelineDependencies,
              ];
              children.push(updatedDep); // Push to children if found and removed
            }
          }

          return updatedDep; // Return the updated dep object
        })
        // Remove the current segment itself from dependencies
        .filter((dep) => {
          if (type === "ETL_PIPELINE") {
            return !(
              dep.etlProviderInstanceId === etlProviderInstanceId &&
              dep.name === name
            );
          }
          return dep.id !== id;
        });

      console.log("Updated Dependencies:", updatedArr);
      console.log("Children Dependencies Updated:", children);

      // Return the updated state with a new dependencies array
      return {
        ...state,
        dependencies: updatedArr, // Use updatedArr instead of clone for immutability
      };
    }

    case "SELECT_DEPENDENCY_TYPE": {
      // Find Current Segment
      const currentSegment = action?.payload?.pipeline;

      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      //Update Deps based on pipeline index and dataSource index
      return {
        ...state,
        dependencies: update(state?.dependencies, {
          [findCurrentIndex]: {
            type: { $set: action?.payload?.type },
            id: { $set: "" },
            name: { $set: "" },
            dataQualityThreshold: { $set: null },
            etlProviderInstanceId: { $set: null },
          },
        }),
      };
    }

    case "INSERT_SEGMENT": {
      // Find Current Segment
      const newSegment = action?.payload?.newSegment;
      const segmentsToUpdate = action?.payload?.segmentsToUpdate;

      console.log({ newSegment, segmentsToUpdate }, "INSERT SEGMENTS");

      // Map over the current dependencies and replace the ones that match the updated segments
      const updatedDependencies = state.dependencies.map((dep) => {
        // Check if the current dependency exists in segmentsToUpdate
        const updatedSegment = segmentsToUpdate.find(
          (updated) => updated.uid === dep.uid
        );

        // If found in the updated segments, return the updated version; otherwise, return the original
        return updatedSegment ? updatedSegment : dep;
      });

      // Add the new segment to the dependencies
      updatedDependencies.push(newSegment);

      // Return the new state with the updated dependencies array
      return {
        ...state,
        dependencies: updatedDependencies, // Replace old dependencies with updated dependencies
        isInsertingDep: false,
      };
    }

    case "SELECT_DATA_SOURCE": {
      // Find Current Segment
      const currentSegment = action?.payload?.pipeline;
      console.log({ action }, "SELECTION OF DATA SOURCE");
      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      //Update Deps based on pipeline index and dataSource index
      return {
        ...state,
        showChangeThreshold: {
          dataSourceId: action?.payload?.dataSource?.value,
        },
        dependencies: update(state?.dependencies, {
          [findCurrentIndex]: {
            name: { $set: action?.payload?.dataSource?.label },
            id: { $set: action?.payload?.dataSource?.value },
          },
        }),
      };
    }

    case "SELECT_TRANSFER": {
      // Find Current Segment
      const currentSegment = action?.payload?.pipeline;
      console.log({ action }, "SELECTION OF TRANSFER");
      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      //Update Deps based on pipeline index and dataSource index
      return {
        ...state,
        dependencies: update(state?.dependencies, {
          [findCurrentIndex]: {
            name: { $set: action?.payload?.transfer?.label },
            id: { $set: action?.payload?.transfer?.value },
          },
        }),
      };
    }

    case "SELECT_ETL_INSTANCE": {
      // Find Current Segment
      const currentSegment = action?.payload?.pipeline;
      console.log({ action }, "SELECTION OF ETL INSTANCE");
      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      //Update Deps based on pipeline index and dataSource index
      return {
        ...state,
        dependencies: update(state?.dependencies, {
          [findCurrentIndex]: {
            name: { $set: action?.payload?.name },
          },
        }),
      };
    }

    case "SET_NEW_ETL": {
      // Find Current Segment
      const currentSegment = action?.payload?.segment;
      console.log({ action }, "SELECTION OF NEW ETL");
      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      //Update Deps based on pipeline index with ETL Id object
      return {
        ...state,
        addNewPipeline: false,
        dependencies: update(state?.dependencies, {
          [findCurrentIndex]: {
            name: { $set: action?.payload?.newPipelineName },
          },
        }),
      };
    }

    case "REMOVE_DATA_SOURCE": {
      // Find Current Segment
      const currentSegment = action?.payload?.pipeline;

      const isTermination = currentSegment?.isTermination;
      const sourceLength = currentSegment?.dataSourceDependencies?.length;

      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      // Find The Index of the dataSource within the pipeline
      const sourceIndex = action?.payload?.sourceIndex;

      if (isTermination && sourceLength === 1) {
        //Update Deps based on pipeline index and remove dataSource index
        return {
          ...state,
          dependencies: update(state?.dependencies, {
            [findCurrentIndex]: {
              dataSourceDependencies: { $set: [] },
            },
          }),
        };
      } else {
        //Update Deps based on pipeline index and remove dataSource index
        return {
          ...state,
          dependencies: update(state?.dependencies, {
            [findCurrentIndex]: {
              dataSourceDependencies: { $splice: [[sourceIndex, 1]] },
            },
          }),
        };
      }
    }

    case "ADD_DATA_SOURCE": {
      // Find Current Segment
      const currentSegment = action?.payload?.pipeline;

      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      const newDataSource = {
        label: "",
        value: "",
        dataQualityThreshold: "",
      };

      //Update Deps based on pipeline index and add dataSource
      return {
        ...state,
        dependencies: update(state?.dependencies, {
          [findCurrentIndex]: {
            dataSourceDependencies: {
              $push: [newDataSource],
            },
          },
        }),
      };
    }

    case "UPDATE_FIELD": {
      const fieldName = action?.payload?.field;
      const fieldValue = action?.payload?.value;

      return {
        ...state,
        [fieldName]: fieldValue,
      };
    }

    case "SET_TAGS": {
      return {
        ...state,
        tagInstanceInputs: action?.payload,
      };
    }

    case "SET_AVAILABLE_PIPELINES": {
      return {
        ...state,
        availablePipelines: action?.payload?.pipelines,
      };
    }

    case "TOGGLE_CHANGE_DATASOURCE_THRESHOLD": {
      console.log({ action }, "TOGGLE CHANGE THRESHOLD");
      return {
        ...state,
        showChangeThreshold: action?.payload ?? false,
      };
    }

    case "TOGGLE_ADD_NEW_PIPELINE": {
      return {
        ...state,
        addNewPipeline: action?.payload ?? false,
      };
    }

    case "UPDATE_DATASOURCE_THRESHOLD": {
      console.log({ action }, "UPDATE THRESHOLD");
      const currentDataSourceId = action?.payload?.dataSourceId;

      const cleanDeps = state?.dependencies.map((dep) => {
        if (dep?.type === "DATA_SOURCE" && dep?.id === currentDataSourceId) {
          return {
            ...dep,
            dataQualityThreshold: Number(action?.payload?.newThreshold),
          };
        } else {
          return { ...dep };
        }
      });

      return {
        ...state,
        dependencies: cleanDeps,
        showChangeThreshold: false,
      };
    }

    case "ENTRY": {
      const name = action?.payload?.name;
      const provider = action?.payload?.provider;

      return {
        ...state,
        workflowName: name,
        etlProviderInstanceId: provider,
      };
    }

    case "SET_PROVIDER": {
      console.log({ action }, "SETTING PROVIDER");
      // Find Current Segment
      const currentSegment = action?.payload?.pipeline;
      console.log({ currentSegment }, "SETTING PROVIDER");
      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );
      console.log({ findCurrentIndex }, "SETTING PROVIDER");
      //Update Deps based on pipeline index and dataSource index
      return {
        ...state,
        dependencies: update(state?.dependencies, {
          [findCurrentIndex]: {
            etlProviderInstanceId: {
              $set: action?.payload?.etlProviderInstanceId,
            },
          },
        }),
      };
    }

    case "RESET_FORM": {
      const getZone = format(new Date(), "XXX", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      //initialize the reducer state
      const initialState = {
        showChangeThreshold: false,
        addNewPipeline: false,
        etlProviderInstanceId: null,
        workflowName: "",
        zone: getZone,
        hour: { value: "1", label: "1" },
        min: { value: "0", label: ":00" },
        isAm: { value: false, label: "PM" },
        dependencies: [
          {
            name: "",
            dataSourceDependencies: [],
            pipelineDependencies: [],
            transformationDependencies: [],
            isTermination: true,
            uid: uuid(),
          },
        ],
      };

      return {
        ...initialState,
      };
    }
  }
};

export default CreateReducer;
